jQuery(document).ready(function($){
	var mainHeader = $('.auto-hide-header'),
		secondaryNavigation = $('.as16-secondary-nav'),
		//this applies only if secondary nav is below intro section
		belowNavHeroContent = $('.sub-nav-hero'),
		headerHeight = mainHeader.height();
	
	//set scrolling variables
	var scrolling = false,
		previousTop = 0,
		currentTop = 0,
		scrollDelta = 10,
		scrollOffset = 450;

	mainHeader.on('click', '.nav-trigger', function(event){
		// open primary navigation on mobile
		event.preventDefault();
		mainHeader.toggleClass('nav-open');
	});

	$(window).on('scroll', function(){
		if( !scrolling ) {
			scrolling = true;
			(!window.requestAnimationFrame)
				? setTimeout(autoHideHeader, 450)
				: requestAnimationFrame(autoHideHeader);
		}
	});

	// $(window).on('resize', function(){
	// 	headerHeight = mainHeader.height();
	// });

	$(window).on('load resize', function(){
      var MastheadHeight = $('header').outerHeight(true);
      $('.main').css({ 'margin-top': MastheadHeight-1 });
    }).resize();

	function autoHideHeader() {
		var currentTop = $(window).scrollTop();

		( belowNavHeroContent.length > 0 ) 
			? checkStickyNavigation(currentTop) // secondary navigation below intro
			: checkSimpleNavigation(currentTop);

	   	previousTop = currentTop;
		scrolling = false;
	}

	function checkSimpleNavigation(currentTop) {
		//there's no secondary nav or secondary nav is below primary nav
	    if (previousTop - currentTop > scrollDelta) {
	    	//if scrolling up...
	    	mainHeader.removeClass('is-hidden');
	    } else if( currentTop - previousTop > scrollDelta && currentTop > scrollOffset) {
	    	//if scrolling down...
	    	mainHeader.addClass('is-hidden');
	    }
	}

	function checkStickyNavigation(currentTop) {
		//secondary nav below intro section - sticky secondary nav
		var secondaryNavOffsetTop = belowNavHeroContent.offset().top - secondaryNavigation.height() - mainHeader.height();
		
		if (previousTop >= currentTop ) {
	    	//if scrolling up... 
	    	if( currentTop < secondaryNavOffsetTop ) {
	    		//secondary nav is not fixed
	    		mainHeader.removeClass('is-hidden');
	    		secondaryNavigation.removeClass('fixed slide-up');
	    		belowNavHeroContent.removeClass('secondary-nav-fixed');
	    	} else if( previousTop - currentTop > scrollDelta ) {
	    		//secondary nav is fixed
	    		mainHeader.removeClass('is-hidden');
	    		secondaryNavigation.removeClass('slide-up').addClass('fixed'); 
	    		belowNavHeroContent.addClass('secondary-nav-fixed');
	    	}
	    	
	    } else {
	    	//if scrolling down...	
	 	  	if( currentTop > secondaryNavOffsetTop + scrollOffset ) {
	 	  		//hide primary nav
	    		mainHeader.addClass('is-hidden');
	    		secondaryNavigation.addClass('fixed slide-up');
	    		belowNavHeroContent.addClass('secondary-nav-fixed');
	    	} else if( currentTop > secondaryNavOffsetTop ) {
	    		//once the secondary nav is fixed, do not hide primary nav if you haven't scrolled more than scrollOffset 
	    		mainHeader.removeClass('is-hidden');
	    		secondaryNavigation.addClass('fixed').removeClass('slide-up');
	    		belowNavHeroContent.addClass('secondary-nav-fixed');
	    	}

	    }
	}

	// Smooth scroll to anchor
	$('a[href*="#"]')
	// Remove links that don't actually link to anything
	.not('[href="#"]')
	.not('[href="#0"]')
	.click(function(event) {
	    // On-page links
	    if (
			location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
			location.hostname === this.hostname
	    ) {
			// Figure out element to scroll to
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
			// Does a scroll target exist?
			if (target.length) {
				// Only prevent default if animation is actually gonna happen
				event.preventDefault();
				$('html, body').animate({
					scrollTop: target.offset().top
				}, 500, function() {
					// Callback after animation
					// Must change focus!
					var $target = $(target);
					$target.focus();
					if ($target.is(":focus")) { // Checking if the target was focused
						return false;
					} else {
						$target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
						$target.focus(); // Set focus again
					}
				});
			}
	    }
	});

	$(window).scroll(function() {
    	if ($(document).scrollTop() > 150) {
    		$('.back-top').addClass('visible');
    	} else {
      		$('.back-top').removeClass('visible');
    	}
  	});

  	// Add active class to current navigation link

  	$(function() {
  		var url = location.origin + location.pathname;
  		url = url.replace(/^.*\/\/[^\/]+/, '');
  		$('.navbar-nav a[href$="' + url + '"]').closest('li').addClass('active');
  	});

  	// Initialize matchHeight

  	$(function() {
  		$('.matchHeight').matchHeight();
  	});

  	/*
  	 * Animation.css controlls
  	 */

  	$(function() {
  		$('a.btn').mouseover(function(){
  			$(this).addClass('animated pulse').one('animationend', function(){
  				$(this).removeClass('animated pulse');
  			});
  		});
  		// $('.phone-number').waypoint(function() { 
  		// 		console.log('Scrolled into frame');
  		// 		$(".phone-number").addClass('animated fadeInDown').one('animationend', function(){
  		// 			$(this).removeClass('animated fadeIn');
  		// 		});
  		// 	},
  		// 	{ offset: '100%' }
  		// );
  	});

});



	





